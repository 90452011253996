/* CSSTransition classes for `fade` */

.fade-enter {
  opacity: 0;
  transform-origin: top right;
  transform: scale(0.75, 0.5625);
  visibility: hidden;
}

.fade-enter-active {
  opacity: 1;
  transform: none;
  visibility: visible;
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    transform 200ms cubic-bezier(0.4, 0, 0.2, 1) 81ms;
}

.fade-exit {
  opacity: 1;
  visibility: visible;
  transform: none;
}

.fade-exit-active {
  opacity: 0;
  transform: scale(0.75, 0.5625);
  transform-origin: top right;
  visibility: visible;
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 81ms,
    transform 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.fade-exit-done {
  opacity: 0;
  transform: scale(0.75, 0.5625);
  visibility: hidden;
}
